<template lang="html">
  <!-- single item markup -->
  <div
    class="item item__single"
    @mouseenter="hoverFunction ? mouseEnter() : ''"
    @mouseleave="hoverFunction ? mouseLeave() : ''"
    @mousemove="hoverFunction ? mouseMove($event) : ''"
    :class="[
      {
        shy: shy && !isHovering,
        relatedClass: relatedItem
      }
    ]"
  >
    <!-- single item wrapper -->
    <div class="item__single__wrapper" @click="openRecordDetial">
      <img
        :src="`${s3RootPath}${imageName}` | imageResizedPath('large')"
        class="item__cover"
        alt=""
      />

      <!-- single item overlay wrapper -->
      <div v-show="!hoverFunction" class="item__overlay__wrapper">
        <div
          class="item__overlay"
          :style="[
            hoverColor && getMobileSizeType.mobile == false
              ? { background: overlayBackgroundColor }
              : ''
          ]"
        >
          <h4>
            {{ name }}
          </h4>
        </div>
      </div>

      <!-- End single item overlay wrapper -->
    </div>

    <!--end single item wrapper -->
    <div v-if="removeSingleItemId" class="remove__record" @click="removeRecord">
      {{ $t("collection.collection_remove_record") }}
    </div>
  </div>

  <!-- end single item -->
</template>

<script>
import { mapGetters } from "vuex";
import { convertHex } from "@/utils/common";
export default {
  name: "SingleItem",
  props: {
    name: String,
    imageName: String,
    sizeType: String,
    hoverFunction: Boolean,
    relatedItem: Boolean,
    shy: Boolean,
    hoverColor: String,
    removeSingleItemId: Number
  },
  beforeMount() {
    this.s3RootPath = this.s3Path;
  },
  created() {
    // console.log(this.imageName + "single");
  },
  data() {
    return {
      isHovering: false,
      opacity: 80
    };
  },
  computed: {
    ...mapGetters("common", ["getMobileSizeType"]),

    widthClassObject() {
      if (!this.relatedItem) {
        return {
          "item__single__width--1": this.sizeType == "landscape",
          // "item__single__width--2": this.sizeType == "landscape",
          "item__single__width--3": this.sizeType == "portrait"
        };
      }
    },
    overlayBackgroundColor: function() {
      return convertHex(this.hoverColor, this.opacity);
    }
  },

  methods: {
    mouseEnter: function() {
      this.isHovering = true;
      this.$emit("updateShowRecord");
    },

    mouseLeave: function() {
      this.isHovering = false;
      this.$emit("updateShowRecord");
    },

    mouseMove: function(e) {
      this.$emit("updateCursor", e);
    },
    openRecordDetial() {
      this.$emit("openRecordDetial");
    },
    removeRecord() {
      this.$emit("removeSingleRecord");
    }
  }
};
</script>

<style lang="scss" scoped>
.item {
  &__cover {
    width: 100%;
    height: 100%;
  }
  &__overlay {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    font-size: 36px;
    color: $color-black;
    background: transparent;
    padding: 15px 0px;
    cursor: pointer;
    @include respond-to(screen-md) {
      color: $color-white;
      background: rgba(201, 60, 27, 0.8);
      position: absolute;
      padding: 15px;
    }

    &__wrapper {
      position: relative;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      @include transition(all 200ms ease-in-out);
      opacity: 1;
      color: white;
      @include fontfamily(GraCompact);

      @include respond-to(screen-md) {
        opacity: 0;
        position: absolute;
      }

      p {
        z-index: 1;
        line-height: 1.2;
        font-size: 16px;
      }
      h4 {
        font-size: 16px;
        z-index: 1;
        font-weight: 400;
        @include respond-to(screen-md) {
          font-size: 23px;
        }
      }
    }
  }
  &__single {
    overflow: hidden;
    // cursor: crosshair;
    // width: calc(20% - 16px);
    // width: 46%;
    width: 48%;
    margin-bottom: 5%;
    // transition: all 300ms ease-in-out;
    // animation: fadeIn 1s;

    &.relatedClass {
      width: 100%;
    }
    @include respond-to(screen-md) {
      width: 23.125%;
      margin-bottom: 2.5%;
    }
    @include respond-to(screen-xl) {
      width: 15%;
      margin-bottom: 2%;
    }
    &:hover .item__overlay__wrapper {
      opacity: 1;
    }

    img {
      object-fit: cover;
    }
    &__wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
    }
    &.shy {
      opacity: 0.3;
    }
    &.not__shy {
      opacity: 1;
    }
    .remove__record {
      margin-top: 15px;
      cursor: pointer;
      text-transform: lowercase;
      text-align: right;
      text-decoration: underline;
    }
  }

  .custom__cursor {
    position: fixed;
    z-index: 2;
    font-size: 16px;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
