var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item item__single",class:[
    {
      shy: _vm.shy && !_vm.isHovering,
      relatedClass: _vm.relatedItem
    }
  ],on:{"mouseenter":function($event){_vm.hoverFunction ? _vm.mouseEnter() : ''},"mouseleave":function($event){_vm.hoverFunction ? _vm.mouseLeave() : ''},"mousemove":function($event){_vm.hoverFunction ? _vm.mouseMove($event) : ''}}},[_c('div',{staticClass:"item__single__wrapper",on:{"click":_vm.openRecordDetial}},[_c('img',{staticClass:"item__cover",attrs:{"src":_vm._f("imageResizedPath")(("" + _vm.s3RootPath + _vm.imageName),'large'),"alt":""}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFunction),expression:"!hoverFunction"}],staticClass:"item__overlay__wrapper"},[_c('div',{staticClass:"item__overlay",style:([
          _vm.hoverColor && _vm.getMobileSizeType.mobile == false
            ? { background: _vm.overlayBackgroundColor }
            : ''
        ])},[_c('h4',[_vm._v(" "+_vm._s(_vm.name)+" ")])])])]),(_vm.removeSingleItemId)?_c('div',{staticClass:"remove__record",on:{"click":_vm.removeRecord}},[_vm._v(" "+_vm._s(_vm.$t("collection.collection_remove_record"))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }